import { defineElement } from '../../utility/elements.js';
import defs from './defs.js';

const fn = async () => {
  const { origin, pathname, search } = window.location;

  const response = await fetch(`${origin}/data${pathname}${search}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  });

  const json = await response.json();
  const el = document.querySelector('x-view-home');
  Object.keys(json).forEach(key => {
    el[key] = json[key];
  });

  defs.views.forEach(view => {
    defineElement(`x-view-${view.name}`, view.component);
  });

  defs.components.forEach(component => {
    defineElement(`x-${component.name}`, component.component);
  });

  el.setAttribute('upgrade', '');
};

fn();
