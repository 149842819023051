import viewTemplate from '../view.js';

import bookmarkTemplate from '../../components/bookmark/template.js';

const isBrowser = typeof window !== 'undefined';

export default (context, data = {}) => {
  const { html } = context;
  const { staticAppUrl, user } = data;

  const bookmark = {
    id: 351,
    accountId: 2,
    url:
      'https://hackernoon.com/stop-infinite-scrolling-on-your-website-now-ie6rg31eu',
    siteId: 182,
    added: '2019-10-15T20:03:56.475Z',
    updated: '2019-10-15T20:03:56.475Z',
    notes: '',
    error: null,
    readingList: true,
    duplicate: null,
    archived: false,
    siteTitle:
      'Stop Infinite Scrolling on Your Website - Now! - By Monish reddy',
    siteDescription: null,
    siteUrl:
      'https://hackernoon.com/stop-infinite-scrolling-on-your-website-now-ie6rg31eu',
    siteMeta: null,
    siteContentType: 'text/html',
    titleSort: null,
    labels: [],
    score: 0,
    siteFavIcon:
      'https://images.weserv.nl/?w=32&url=https%3A%2F%2Fhackernoon.com%2Ffavicon.png',
    siteImage: 'https://hackernoon.com/drafts/4n6xb314t.png',
    siteActions: [],
    shareId: null
  };

  const content = html`
    <p class="emphasise title">
      Take back control of your bookmarks
      <img src="${staticAppUrl}/images/reading.svg" alt="" />
    </p>

    <div class="inner">
      <p class="emphasise subtitle">
        Scrapp is a lightweight, universal bookmark manager that helps you
        <strong>manage</strong>, <strong>maintain</strong> and
        <strong>read</strong> your bookmarks, wherever you are
      </p>

      <p class="emphasise">
        ${user
          ? html`
              <a class="button" href="/bookmarks">Go to bookmarks</a>
            `
          : html`
              <a class="button" href="/user">Sign up for free now</a>
            `}
      </p>

      <h1>Manage</h1>

      <div class="feature">
        <img src="${staticAppUrl}/images/bookshelves.svg" alt="" />

        <p>
          Scrapp lets you easily add, view and edit bookmarks wherever you are.
          Add directly on the site, use our browser extension, or even add using
          a QR code.
        </p>
      </div>

      <h1>Maintain</h1>

      <div class="feature reverse">
        <img src="${staticAppUrl}/images/done-checking.svg" alt="" />

        <p>
          Sort your bookmarks into groups using labels, add items to your
          reading list and archive once your done. Powerful search lets you find
          those bookmarks by title, description and even page content whenever
          you need them.
        </p>
      </div>

      <h1>Read</h1>

      <div class="feature">
        <img src="${staticAppUrl}/images/reading-book.svg" alt="" />

        <p>
          Read any of your bookmarks in a simplified reader view or, for
          bookmarks containing rich media, view the embedded content right here
          on the site. Scrapp also stores a simple readable view of all your
          read items so you can even access the content of your bookmarks when
          offline.
        </p>
      </div>

      <h1>Other features</h1>

      <ul class="other-features">
        <li>
          <strong>Lightweight</strong> - access the site on the latest feature
          phone or a less capable feature phone, the same great features for
          every one
        </li>
        <li>
          <strong>Flexible</strong> - add bookmarks from anywhere and group by
          label, add to a reading list or archive.
        </li>
        <li>
          <strong>Fast</strong> - bookmarks are added instantly while we process
          the site to extract content and meta data
        </li>

        <li>
          <strong>Installable</strong> - add to homescreen or desktop straight
          from the browser
        </li>
        <li>
          <strong>Reader View</strong> - read simplified versions of bookmarks
          directly on the site
        </li>
        <li>
          <strong>Media Embeds</strong> - for supported sites (YouTube, Twitter,
          SoundCloud and many more) view the embeded content directly on the
          site
        </li>
        <li>
          <strong>Meta Data</strong> - meta data such as page author and
          bookmarks are extracted and shown alongside the bookmark details
        </li>
        <li>
          <strong>Offline</strong> - read items even with no network connection
        </li>
        <li>
          <strong>Notifications</strong> - optionally turn on a daily
          notification to remind you what's on your reading list
        </li>
      </ul>

      <p class="emphasise">
        ${user
          ? html`
              <a class="button" href="/bookmarks">Go to bookmarks</a>
            `
          : html`
              <a class="button" href="/user">Sign up for free now</a>
            `}
      </p>
    </div>
  `;

  return viewTemplate(context, content, data);
};
