import commonDefs from '../defs.js';

import Bookmark from '../../components/bookmark/index.js';
import ViewHome from '../../views/home/index.js';

export default {
  views: [
    {
      name: 'home',
      component: ViewHome
    }
  ],

  components: commonDefs.concat([
    {
      name: 'bookmark',
      component: Bookmark
    }
  ])
};
