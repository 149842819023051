import XElement from '../../base/element.js';
import template from './template.js';
import ViewMixin from '../../mixins/view.js';

class ViewHome extends ViewMixin(XElement) {
  render() {
    return super.render(template);
  }
}

export default ViewHome;
